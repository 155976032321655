import { PageHead } from '@/core/components/PageHead';

import { Login } from '@/features/Login';

const LoginPage = () => (
  <>
    <PageHead title="Login" />
    <Login />
  </>
);

LoginPage.displayName = 'LoginPage';

export default LoginPage;
