import { css } from 'styled-components';

const primary = css`
  background-color: ${({ theme }) => theme.semanticColors.interactive.primary};

  &:hover {
    background-color: ${({ theme }) => theme.semanticColors.hover.primary};
  }

  &:active {
    background-color: ${({ theme }) => theme.semanticColors.active.primary};
  }
`;

const secondary = css`
  background-color: ${({ theme }) => theme.semanticColors.interactive.secondary};

  &:hover {
    background-color: ${({ theme }) => theme.semanticColors.hover.secondary};
  }

  &:active {
    background-color: ${({ theme }) => theme.semanticColors.active.secondary};
  }
`;

const tertiary = css`
  border: 2px solid ${({ theme }) => theme.semanticColors.interactive.primary};
  background-color: ${({ theme }) => theme.semanticColors.interactive.tertiary};
  color: ${({ theme }) => theme.semanticColors.links.highContrast};

  &:hover {
    background-color: ${({ theme }) => theme.semanticColors.hover.tertiary};
    color: ${({ theme }) => theme.semanticColors.text.interactive};
  }

  &:active {
    background-color: ${({ theme }) => theme.semanticColors.active.tertiary};
    color: ${({ theme }) => theme.semanticColors.text.interactive};
  }

  &:focus {
    background-color: ${({ theme }) => theme.semanticColors.hover.tertiary};
    color: ${({ theme }) => theme.semanticColors.text.interactive};
  }

  &:disabled,
  &:disabled:hover {
    border-color: ${({ theme }) => theme.semanticColors.disabled[2]};
    background-color: ${({ theme }) => theme.semanticColors.interactive.tertiary};
    color: ${({ theme }) => theme.semanticColors.disabled[2]};
  }
`;

const tertiaryLight = css`
  ${tertiary}

  color: ${({ theme }) => theme.colors.vibrantBlue80Dark};
`;

const disabled = css`
  border: 2px solid ${({ theme }) => theme.semanticColors.disabled[2]};
  background-color: ${({ theme }) => theme.semanticColors.interactive.tertiary};
  color: ${({ theme }) => theme.semanticColors.disabled[2]};
  cursor: auto;
`;

const ghost = css`
  background-color: ${({ theme }) => theme.semanticColors.interactive.tertiary};
  color: ${({ theme }) => theme.semanticColors.links.highContrast};

  &:hover {
    background-color: ${({ theme }) => theme.semanticColors.hover.ui};
    color: ${({ theme }) => theme.semanticColors.hover.primaryText};
  }

  &:active {
    background-color: ${({ theme }) => theme.semanticColors.active.secondary};
    color: ${({ theme }) => theme.semanticColors.text.interactive};
  }

  &:disabled,
  &:disabled:hover {
    border: none;
    background-color: ${({ theme }) => theme.semanticColors.interactive.tertiary};
    color: ${({ theme }) => theme.semanticColors.disabled[2]};
  }

  &:focus:not(:active) {
    background-color: ${({ theme }) => theme.semanticColors.interactive.tertiary};
    color: ${({ theme }) => theme.semanticColors.links.highContrast};
  }
`;

const textNegative = css`
  &:hover {
    background-color: ${({ theme }) => theme.semanticColors.hover.primary};
  }

  &:active {
    background-color: ${({ theme }) => theme.semanticColors.active.primary};
  }

  &:disabled,
  &:disabled:hover {
    border: none;
    background: none;
    color: ${({ theme }) => theme.semanticColors.disabled[2]};
  }

  &:focus:not(:active) {
    outline: none;
  }
`;

const danger = css`
  background-color: ${({ theme }) => theme.semanticColors.support.danger};

  &:hover {
    background-color: ${({ theme }) => theme.semanticColors.hover.danger};
  }

  &:active {
    background-color: ${({ theme }) => theme.semanticColors.active.danger};
  }

  &:focus:not(:active) {
    outline-color: ${({ theme }) => theme.semanticColors.support.danger};
    background-color: ${({ theme }) => theme.semanticColors.support.danger};
  }
`;

export const variants = {
  primary,
  secondary,
  tertiary,
  tertiaryLight,
  ghost,
  textNegative,
  danger,
  disabled,
};
