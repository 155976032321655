import styled, { css } from 'styled-components';

import { outline } from '@/core/styles/mixins/interactive';
import { bodyText } from '@/core/styles/mixins/typography';

import { ButtonProps } from './Button';
import { variants } from './variants';

const buttonSize = (size: ButtonProps['size']) => {
  switch (size) {
    case 'medium': {
      return css`
        width: 178px;
        height: 40px;
      `;
    }

    case 'small': {
      return css`
        width: 178px;
        height: 48px;
      `;
    }

    case 'fullWidth': {
      return css`
        width: 100%;
        height: 48px;
      `;
    }

    case 'auto': {
      return css`
        width: auto;
        height: 48px;
      `;
    }

    default:
      return css`
        width: 168px;
        height: 48px;
      `;
  }
};

export const StyledButton = styled.button<ButtonProps>`
  ${bodyText}

  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-width: 110px;
  padding: 0 16px;
  color: ${({ theme }) => theme.semanticColors.text.interactive};
  cursor: pointer;

  &:disabled,
  &:disabled:hover {
    background-color: ${({ theme }) => theme.semanticColors.interactive.disabled};
    color: ${({ theme }) => theme.semanticColors.disabled[3]};
    cursor: default;
  }

  &:focus-visible:not(:active) {
    ${outline}
  }

  ${({ size }) => buttonSize(size)}
  ${({ variant }) => (variant ? variants[variant] : variants.primary)};
`;
