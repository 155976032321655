import {
  UnauthenticatedTemplate,
  useIsAuthenticated,
  useMsal,
} from '@azure/msal-react';
import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { InteractionStatus } from '@azure/msal-browser';

import { Image } from '@/core/components/Image';
import { routePaths } from '@/core/constants/routes';

import {
  HeaderText,
  LoginBox,
  LoginItems,
  LoginText,
  StyledButton,
  StyledLogin,
  TrademarkText,
} from './Login.styles';
import { loginRequest } from '../../../authConfig';

export const Login = () => {
  const router = useRouter();
  const { inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const { instance } = useMsal();

  const handleLogin = () => {
    instance.loginRedirect(loginRequest)
      .catch((e: unknown) => {
        console.log(e);
      });
  };

  useEffect(() => {
    if (inProgress === InteractionStatus.None && isAuthenticated) {
      router.push(routePaths.home);
    }
  }, [
    isAuthenticated,
    inProgress,
  ]);

  return (
    <UnauthenticatedTemplate>
      <StyledLogin>
        <div>
          <LoginBox>
            <LoginItems>
              <Image
                src="/images/logo.svg"
                alt="logo"
              />
              <HeaderText>Welcome</HeaderText>
              <LoginText>Log in to continue</LoginText>
            </LoginItems>
            <StyledButton
              type="button"
              onClick={handleLogin}
            >
              Log in
            </StyledButton>
          </LoginBox>
          <TrademarkText>
            Nodal is a trademark of Milliman, Inc., registered in the U.S.
          </TrademarkText>
        </div>
      </StyledLogin>
    </UnauthenticatedTemplate>
  );
};
