import styled from 'styled-components';

import { Button } from '@/core/components/Button';
import { spacing } from '@/core/styles/mixins/spacing';
import {
  fontSize,
  fontWeight,
} from '@/core/styles/mixins/typography';

export const StyledLogin = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  background: conic-gradient(from 148.09deg at 50% 50%, #004877 -84.19deg, #000 90.51deg, #004877 275.81deg, #000 450.51deg);
`;

export const LoginBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: ${spacing(63.25)};
  height: ${spacing(41.5)};
  margin: 0 0 ${spacing(3)};
  padding: ${spacing(6)};
  background-color: ${({ theme }) => theme.colors.white};
`;

export const LoginItems = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TrademarkText = styled.p`
  ${fontSize('smaller')}
  ${fontWeight('semiBold')}
  color: ${({ theme }) => theme.colors.white};
  text-align: left;
  letter-spacing: 0.5px;
`;

export const HeaderText = styled.p`
  margin: ${spacing(3)} 0 ${spacing(2)};
  color: ${({ theme }) => theme.customMultiselectColors.veryDarkGray};
  font-size: ${spacing(3.5)};
  line-height: ${spacing(4.5)};
  letter-spacing: 0;
`;

export const LoginText = styled.p`
  ${fontSize('bigger')}
  color: ${({ theme }) => theme.customMultiselectColors.veryDarkGray};
  line-height: ${spacing(3)};
  letter-spacing: 0;
`;

export const StyledButton = styled(Button)`
  max-width: 116px;
`;
