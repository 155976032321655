import {
  forwardRef, ReactNode,
} from 'react';

import { MergeElementProps } from '@/core/interfaces/common';

import { StyledButton } from './Button.styles';

export type ButtonProps = MergeElementProps<'button', {
  children: ReactNode;
  variant?: 'primary' | 'secondary' | 'tertiary' | 'tertiaryLight' | 'ghost' | 'textNegative' | 'danger' | 'disabled';
  size?: 'default' | 'medium' | 'small' | 'fullWidth' | 'auto';
}>;

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(({
  children,
  variant = 'primary',
  size = 'default',
  ...rest
}, ref) => (
  <StyledButton
    variant={variant}
    size={size}
    ref={ref}
    {...rest}
  >
    {children}
  </StyledButton>
));
